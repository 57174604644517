import styled from "styled-components"
import { AppBar } from "@material-ui/core"
import IMSquare from "../../assets/im-square.svg"
import IconButton from "@material-ui/core/IconButton"

export const CustomAppBar = styled(AppBar)`
  font-family: ${({ theme }) => theme.fonts.primary};
  padding: 0.85em 0;
  transition: all 0.3s ease;
  // background-color: rgba(19, 39, 78, 0.73);
  background-color: rgba(19, 39, 78);
  position: static;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 1em 0;
  }
`
export const ItemContainer = styled.div`
  padding-left: 2em;
  padding-right: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    // filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))
  }

  & > .logo {
    flex-basis: 450px;
    padding-right: 10px;

    display: flex;
    gap: 15px;
    align-items: end;

    .mark {
      flex-basis: 80px;
      line-height: 1;
    }
  }

  & > .nav_area {
    flex-basis: 320px;
    display: flex;
    flex-wrap: wrap;

    & > ul {
      flex-basis: 100%;
    }

    .navbar-nav {
      display: flex;
      flex-direction: row;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      justify-content: space-between;
    }
  }
`

export const Logo = styled(IMSquare)`
  width: 100%;
  max-width: 80px;
`

export const MenuButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 20px;
  display: none;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: block;
  }

  svg {
    fill: #fff;
    height: 30px;
    width: 30px;
  }
`

export const Tagline = styled.span`
  display: block;
  text-transform: uppercase;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.2em;
`
