import styled from "styled-components"

export const Nav = styled.nav`
    position: relative;

    ${({ theme }) => theme.breakpoints.down("sm")} {
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: #2A3550;
        padding: 0 15px 15px;
        flex-direction: column;
        justify-content: center;
        transform: translateY(-120%);

        &.mobile_open {
            transform: translateY(0);
        }

        .navbar-nav {
            display: block !important;
            border: 0;
            flex-basis: unset !important;

            li {
                text-align: left !important;
            }
        }

        .topNav {
            order: 2;
            margin-top: 0;

            .dropdown-menu { display: none !important; }
            li { margin-bottom: 10px; }
        }

        .bottomNav {
            margin-bottom: 0;

            .dropdown-menu {
                display: block !important;
                background-color: transparent;
                width: auto;
                padding: 0 0 0 15px;
                position: static;

                &:before { display: none; }


                & > li {
                    border: 0 !important;
                    padding: 0;

                    .description, &:first-child {
                        display: none;
                    }
                }
            }
        }
        .bottomNav li {
            .nav-link {
                font-weight: 700;
                font-size: .9rem !important;
                margin-bottom: 0 !important;
            }
            
            font-size: .7rem !important;
            margin-bottom: 5px !important;

            strong { font-weight: 400; }

            .dropdown-menu {
                margin-top: 5px !important;
                margin-bottom: 10px;
            }
        }
    }
`

export const TopNav = styled.ul `
    & > li {
        position: relative;

        &:last-child a {
            padding-right: 0;
        }

        &:hover > .dropdown-menu {
            display: flex;
        }
    }

    .nav-link {
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        padding: 0 40px 0 0;
        margin: 5px 0;
        color: #fff;        
    }

    .dropdown-menu {
        display: none;
        position: absolute;
        z-index: 1000;
        width: 250px;
        right: 0;
        transform: translateX(25%);
        margin-top: 0 !important;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        margin: 0;
        padding: 15px 10px;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: rgb(16 52 124 / 85%);
        background-clip: padding-box;


        & > li {
            flex-basis: 100%;
            margin-bottom: 15px;
            padding: 10px 15px;
            border: 1px solid transparent;
            font-size: .8em;
            color: #cfcfcf;
            display: block;

            transition: all ease-out .3s;
            background-image: linear-gradient(to left,transparent,transparent 50%,#07B1C4 50%,#2680EB);
            background-size: 201% 100%;
            background-position: calc(100% + 1px);

            &:last-child { margin-bottom: 0; }

            a {
                display: block;
                color: #fff;
                font-size: 1em;
                font-weight: 700;
            }

            &:hover {
                border-color: rgb(255 255 255 / 55%);
                color: #fff;
                background-position: 0 0;
            }
        }

        a { color: #fff; text-decoration: none; }
    }
}
`

export const BottomNav = styled.ul`
    border-top: 2px solid #fff;
    margin-top: 5px !important;
    padding-top: 5px !important;

    & > li {
        justify-content: space-between;
        flex-grow: 1;
        position: relative;

        &:nth-child(2) {
            text-align: center;

        }

        &:last-child {
            text-align: right;
        }

        &:hover > .dropdown-menu {
            display: flex;
        }
    }

    .dropdown-menu {
        display: none;
        position: absolute;
        right: 0;
        z-index: 1000;
        width: 700px;
        margin-top: 12px !important;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        margin: 0;
        padding: 15px 25px;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: rgb(16 52 124 / 85%);
        background-clip: padding-box;

        .full-width {
            flex-basis: 100%;
        }

        & > li {
            flex-basis: 48%;
            margin-bottom: 15px;
            padding: 10px 15px;
            border: 1px solid transparent;
            font-size: .8em;
            color: #cfcfcf;
            display: block;

            transition: all ease-out .3s;
            background-image: linear-gradient(to left,transparent,transparent 50%,#07B1C4 50%,#2680EB);
            background-size: 201% 100%;
            background-position: calc(100% + 1px);

            &:first-child {
                border-color: rgb(255 255 255 / 55%);
            }

            a { display: block; color: inherit; }

            strong {
                display: block;
                color: #fff;
                font-size: 1.3em;
            }

            &:hover {
                border-color: rgb(255 255 255 / 55%);
                color: #fff;
                background-position: 0 0;
            }
        }

        a { color: #fff; text-decoration: none; }
    }

    .nav-link {
        font-size: 17px;
        font-weight: 600;
        color: #fff;
        text-decoration: none;
        padding-bottom: 14px;

        // &.dropdown-toggle::after {
        //     display: inline-block;
        //     margin-left: 0.255em;
        //     vertical-align: 0.255em;
        //     content: "";
        //     border-top: 4px solid;
        //     border-right: 4px solid transparent;
        //     border-bottom: 0;
        //     border-left: 4px solid transparent;
        //     transform: rotate(-45deg);
        //     position: relative;
        //     top: 5px;
        //     right: 0px;
        // }
    }

    li.full-width {
        display: block;

    }

    li.full-width:hover{
        background-position: 0 0;
    }

    li:nth-child(1) .dropdown-menu:before {
        left: 89%;
    }
    li:nth-child(2) .dropdown-menu:before {
        left: 93%;
    }
    li:nth-child(3) .dropdown-menu:before {
        left: 99%;
    }
    .dropdown-menu:before {
        content: '';
        position: relative;
        bottom: 38px;
        border: 12px solid transparent;
        border-bottom: 12px solid rgba(2,12,32,0.83);

    }
    
    
`
